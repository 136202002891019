import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import ProjectIndex from '../components/projectIndex';
import SEO from '../components/seo';
import Layout from '../components/layout';
import {
  H2White, H3White, PWhite,
} from '../components/typography';
import Arrow from '../images/arrow-left.svg';
import { getQueryString } from '../components/getQueryString';

const StyledGrid = styled(Grid)`
  margin-bottom: 106px;
  max-width: 89rem;
  width: 100%;
`;

const StyledH2White = styled(H2White)`
  margin-bottom: 25px;

  @media (min-width: 64em) {
    margin-top: 69px;
  }
`;

const StyledH2WhiteRight = styled(StyledH2White)`
  cursor: pointer;

  @media (min-width: 64em) {
    text-align: right;
  }

  &:hover,
  &:focus {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const StyledH3White = styled(H3White)`
  margin-bottom: 30px;
`;

const StyledPWhite = styled(PWhite)`
  margin-bottom: 25px;
`;

const Container = styled.div`
  position: relative;
  margin-bottom: 2.25rem;
`;

const ProjectLink = styled(Link)`
  &:hover .overlay {
    opacity: 0.9;
  }

  &:hover .title {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #008CBA;

  @media (max-width: 64em) {
    display: none;
  }
`;

const OverlayWrapper = styled.div`
  position: relative;
`;

const Button = styled.button`
  color: var(--white);
  width: 182px;
  height: 43px;
  border: 1px solid var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  font-size: 1rem;
  letter-spacing: 0.32px;
  line-height: 1.125rem;
`;

const StyledLink = styled(Link)`
  &:hover,
  &:focus {
    & > svg {
      fill: var(--blue);
      transition: fill .5s ease;
    }
  }
`;

const ArrowLeft = styled(Arrow)`
  height: 14px;
  fill: var(--white);
  margin-right: 8px;
`;

const ResponsiveTable = styled.div`
  overflow: auto;
  width: 100%;
`;

const Table = styled.table`
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.89px;
  line-height: 1.125rem;
  text-align: left;
  margin-bottom: 42px;
  max-width: 375px;
  width: 100%;
`;

const TH = styled.th`
  min-width: 135px;
  padding-bottom: 8px;
  
  @media (max-width: 64em) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const THWithPadding = styled.th`
  padding-top: 8px;
  padding-bottom: 8px;
  
  @media (max-width: 64em) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const TR = styled.tr`
  border-top: 1px solid var(--white);
`;

const Projects = ({ data }) => {
  const [categoryValue, setCategoryValue] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showIndex, setShowIndex] = useState(false);
  const categoryString = decodeURIComponent(getQueryString('category'));
  const projectsList = data.allDatoCmsProject.edges;
  // eslint-disable-next-line no-shadow
  const filter = (filterString) => projectsList.filter(({
    node: project,
  }) => project.category.find((p) => p.name === filterString));

  useEffect(() => {
    setFilteredProjects(projectsList);
    if (categoryString !== 'null') {
      const filterProjects = filter(categoryString);
      setFilteredProjects(filterProjects);
      setCategoryValue(categoryString);
    }
  }, []);

  const allTitle = () => {
    let title = 'all projects';

    if (categoryValue) {
      title = `all ${categoryValue} projects`;
    }

    return title;
  };

  const featuredLink = () => {
    let text = (
      <StyledLink to="/projects">
        <ArrowLeft />
        featured projects
      </StyledLink>
    );

    if (categoryValue) {
      text = (
        <StyledLink to={`/projects?filter=${encodeURIComponent(categoryValue)}`}>
          <ArrowLeft />
          featured
          {' '}
          {categoryValue}
          {' '}
          projects
        </StyledLink>
      );
    }

    return text;
  };

  return (
    <Layout>
      <ProjectIndex showIndex={showIndex} setShowIndex={setShowIndex} />
      <SEO title="Archived" />
      <StyledGrid fluid>
        <Row>
          <Col lg={6} md={6} xs={12}>
            <StyledH2White>
              {allTitle()}
            </StyledH2White>
          </Col>
          <Col lg={6} md={6} xs={12}>
            <StyledH2WhiteRight
              onClick={(e) => {
                e.preventDefault();
                setShowIndex(!showIndex);
              }}
            >
              all projects
            </StyledH2WhiteRight>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} xs={12}>
            <StyledH3White>
              {featuredLink()}
            </StyledH3White>
          </Col>
        </Row>
        {filteredProjects.length === 0
          && (
            <Row>
              <Col lg={12} md={12} xs={12}>
                <PWhite>
                  No results found
                </PWhite>
              </Col>
            </Row>
          )}
        {filteredProjects.length >= 1
          && filteredProjects.map(({ node: project }) => (
            <ProjectLink to={`/projects/${project.slug}`} key={project.id}>
              <Row>
                <Col lg={7} md={7} xs={12}>
                  <Container>
                    <OverlayWrapper>
                      <Img fluid={{ ...project.cover.fluid, aspectRatio: 16 / 9 }} className="project-image" />
                      <Overlay className="overlay">
                        <Button type="button">Read more</Button>
                      </Overlay>
                    </OverlayWrapper>
                  </Container>
                </Col>
                <Col lg={5} md={5} xs={12}>
                  <Container>
                    <H2White className="title">{project.title}</H2White>
                    <StyledPWhite>{`${project.description.substring(0, 200)}...`}</StyledPWhite>
                    <ResponsiveTable>
                      <Table>
                        <thead>
                          <tr>
                            <TH>Year</TH>
                            <TH>{project.started}</TH>
                          </tr>
                          <TR>
                            <THWithPadding>Client</THWithPadding>
                            <THWithPadding>{project.client}</THWithPadding>
                          </TR>
                          <TR>
                            <THWithPadding>Size</THWithPadding>
                            <THWithPadding>{project.size}</THWithPadding>
                          </TR>
                          <TR>
                            <THWithPadding>Cost</THWithPadding>
                            <THWithPadding>{project.cost}</THWithPadding>
                          </TR>
                          <TR>
                            <THWithPadding>Completed</THWithPadding>
                            <THWithPadding>{project.completed}</THWithPadding>
                          </TR>
                        </thead>
                      </Table>
                    </ResponsiveTable>
                  </Container>
                </Col>
              </Row>
            </ProjectLink>
          ))}
      </StyledGrid>
    </Layout>
  );
};

export const query = graphql`
  query ArchivedIndexQuery {
    allDatoCmsProject(
      sort: {
        fields: [meta___publishedAt],
        order: DESC
      },
      filter: {
        meta: { 
          isValid: { eq: true },
          status: { eq: "published" },
        }
      }
    ) {
      distinct(field: category)
      edges {
        node {
          id
          title
          slug
          category {
            name
          }
          description
          started
          client
          size
          cost
          completed
          meta {
            publishedAt
            isValid
            status
          }
          cover {
            fluid(maxHeight: 375) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;

Projects.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Projects;
