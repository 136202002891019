/* eslint-disable import/prefer-default-export */
export const getQueryString = (name) => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-undef
    const url = window.location.href;
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return null;
};
