/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const PWhite = styled.p`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
  white-space: pre-line;
`;

export const PSmallWhite = styled.p`
  color: var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.89px;
  line-height: 1.125rem;
`;

export const Address = styled.address`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
`;

export const H1White = styled.h1`
  color: var(--white);
  font-size: 2.875rem;
  font-weight: 300;
  letter-spacing: 0.92px;
  line-height: 3.375rem;
`;

export const H2White = styled.h2`
  color: var(--white);
  font-size: 1.75rem;
  font-weight: 300;
  letter-spacing: 1.56px;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
`;

export const H2Black = styled(H2White)`
  color: var(--black);
`;

export const H3White = styled.h3`
  color: var(--white);
  font-size: 1.375rem;
  line-height: 1.5rem;
  font-weight: 300;
  letter-spacing: 1.22px;
`;

export const H4White = styled.h4`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  margin-top: 0.25rem;
`;

export const H4Black = styled(H4White)`
  color: var(--black);
`;

export const ULWhite = styled.ul`
  color: var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  margin-bottom: 1.25rem;
`;

/*
 * Forms
 *
 */
export const Input = styled.input`
  color: var(--white);
  display: block;
  width: 100%;
  height: 40px;
  background: var(--black);
  margin-bottom: 1.25rem;
  border: 1px solid var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  padding: 0.5rem;
  font-family: inherit;
`;

export const TextArea = styled.textarea`
  color: var(--white);
  display: block;
  width: 100%;
  height: 40px;
  background: var(--black);
  margin-bottom: 1.25rem;
  border: 1px solid var(--white);
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.5rem;
  padding: 0.5rem;
  height: 200px;
  font-family: inherit;
`;

export const FormButton = styled.button`
  color: var(--white);
  border: 1px solid var(--white);
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.32px;
  line-height: 1.125rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: 110px;
  text-align: center;
  height: 31px;

  &:hover,
  &:focus {
    cursor: pointer;
    color: var(--blue);
    border: 1px solid var(--blue);
    transition: border .5s ease;
  }
`;
