import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import CloseIcon from '@material-ui/icons/Close';
import {
  H2Black,
} from './typography';

const alphabet = [];

const ProjectIndexWrapper = styled.div`
  background-color: var(--white);
  position: fixed;
  height: 100vh;  
  top: 0;
  overflow-x: auto;
  right: 0;
  transition: all .5s ease;
  transform: translateX(200%);
  width: 85%;
  z-index: 999;
  padding: 50px 30px;

  @media (min-width: 64em) {
    padding: 190px 100px;
    width: 50%;
  }

  &.active {
    transform: translateX(0);
  }
`;

const StyledButton = styled.button`
  font-size: 32px;
  position: absolute;
  right: 20px;
  top: 20px;
  transition: color .5s ease;

  @media (min-width: 64em) {
    right: 40px;
    top: 40px;
  }
  
  &:hover,
  &:focus {
    color: var(--blue);
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  && {
    cursor: pointer;
    font-size: 32px;
  }
`;

const StyledHeading = styled(H2Black)`
  color: #2F3942;
  margin-bottom: 30px;
`;

const StyledProjectTitle = styled(Link)`
  color: #1A1A1A;
  display: block;
  font-size: 1.125rem;
  margin-bottom: 30px;
`;

const StyledFeatured = styled.span`
  color: #A7AEB7;
  font-size: 0.75rem;
  margin-left: 10px;
  text-transform: uppercase;
`;

const StyledAlphabetWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: 64em) {
    right: 40px;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: #A7AEB7;
  display: block;
  font-size: 0.75rem;
  margin-bottom: 10px;
  text-transform: uppercase;
`;

function ProjectIndex({ showIndex, setShowIndex }) {
  const data = useStaticQuery(
    graphql`
      query {
        allDatoCmsProject(
          sort: {
            fields: [title],
            order: ASC
          },
          filter: {
            meta: {isValid: {eq: true}, status: {eq: "published"}},
          }
        ) {
          edges {
            node {
              id
              title
              slug
              featured
              meta {
                publishedAt
                isValid
                status
              }
            }
          }
        }
      }
    `,
  );

  const calculateFirstLetter = (title) => {
    const firstLetter = title.charAt(0);
    const lowerFirstLetter = firstLetter.toLowerCase();

    if (alphabet.indexOf(lowerFirstLetter) === -1) {
      alphabet.push(lowerFirstLetter);
      return lowerFirstLetter;
    }

    return lowerFirstLetter;
  };

  const allowScroll = () => {
    document.body.classList.remove('prevent-scroll');
  };

  return (
    <ProjectIndexWrapper className={`${showIndex ? 'active' : 'hidden'}`}>
      <StyledButton
        onClick={(e) => {
          e.preventDefault();
          allowScroll();
          setShowIndex(!showIndex);
        }}
      >
        <StyledCloseIcon />
      </StyledButton>
      <StyledHeading>
        all projects
      </StyledHeading>
      {data.allDatoCmsProject.edges.map(({ node: project }) => (
        <StyledProjectTitle to={`/projects/${project.slug}`} onClick={() => { allowScroll(); }} key={project.id} id={calculateFirstLetter(project.title)}>
          {project.title}
          {project.featured
            && <StyledFeatured>Featured</StyledFeatured>}
        </StyledProjectTitle>
      ))}
      <StyledAlphabetWrapper>
        {alphabet.map((letter) => (
          <StyledAnchorLink key={letter} to={`#${letter}`}>
            {letter}
          </StyledAnchorLink>
        ))}
      </StyledAlphabetWrapper>
    </ProjectIndexWrapper>
  );
}

ProjectIndex.propTypes = {
  showIndex: PropTypes.bool.isRequired,
  setShowIndex: PropTypes.func.isRequired,
};

export default ProjectIndex;
